import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <>
    <ToastContainer />
    <GoogleOAuthProvider clientId="266183557544-n16mt132hicgotrt4c2bqqrflli1velo.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </>
);

reportWebVitals();

import { React, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const StopBackWrapper  = ({ children,stopPaths }) => {
    const location = useLocation();
    useEffect(() => {
        const forward = () => {
            if (stopPaths.includes(currentLocationRef.current)) {
                if (!forwardCalledRef.current) {
                    forwardCalledRef.current = true
                    window.history.forward();
                }
            }
        }
        window.addEventListener('popstate', forward);
        return () => { window.removeEventListener('popstate', forward) }
    }, [JSON.stringify(stopPaths)]);

    useEffect(() => {
        setTimeout(() => {
            currentLocationRef.current = location.pathname
            forwardCalledRef.current = false
        }, 200);
    }, [location.pathname])

    const currentLocationRef = useRef(location.pathname)
    const forwardCalledRef = useRef(false)
    return <>{children}</>
}
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, } from "react";
import ReactGA from "react-ga4";
import InitializeGoogleAnalytics from "./utils/TrackEvents";
import { reactGA, } from "./common/authTokenHandler";
import { HelmetProvider } from 'react-helmet-async';
import { ComparisonDataProvider } from "./helpers/comparisonContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import AllRoutes from './Routes/allRoutes';

ReactGA.initialize(reactGA());

function App() {

  // const stripePromise = loadStripe("pk_test_51ObYISCDiwDgCOAWQFXBBk4fNTwgK0YlI8d0EHG66FjjWiL2Y6gAiNGOWCYviG8IN3fNz81momf64pF0yBqDpdNJ00GPMS30dr");
  // // const stripePromise = loadStripe("pk_live_51ObYISCDiwDgCOAWjh3J798NNd20yIAP9RDhJ14zYfE5nktl4hwOrqaaQwkc0CPso75g6Cted8BnpFeEqZIxD24A00728OHTSG");

  function loadTawkTo() {
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/65f435698d261e1b5f6def07/1hp0v1a6h';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }
  
  useEffect(() => {
    loadTawkTo();
  }, []);


  React.useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);
  return (
    <>
     
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ComparisonDataProvider>
          {/* <Elements stripe={stripePromise} > */}
            <Router>
              <AllRoutes />
            </Router>
            {/* </Elements> */}
          </ComparisonDataProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </>
  );
}

export default App;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCookieAndLocalStorageObject } from '../common/authTokenHandler';


const ComparisonDataContext = createContext();

export const useComparisonData = () => useContext(ComparisonDataContext);

export const ComparisonDataProvider = ({ children }) => {
  const [dashComparisonData, setDashComparisonData] = useState(null);
  const [webStatus, setWebStatus] = useState(false);
  const [dash_json, setDash_Json] = useState({});
  const [userId, setUserId] = useState("");
  const [siteName, setSiteName] = useState(``);
  const [loading, setLoading] = useState(false);
  const [userSites, setUsersSites] = useState([]);
  const [siteLimitInfo, setSiteLimitInfo] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [timerData, setTimerData] = useState({})
  const [screenShot, setScreenShot] = useState('');
  const [partialData, setPartialData] = useState("");
  const [userData, setUserData] = useState({});
  const [siteData, setSiteData] = useState({});

  // console.log("userData",userData);
  useEffect(() => {
    const AllowWeb = getCookieAndLocalStorageObject('checkWeb_siteLimitInfo');
    const userData = getCookieAndLocalStorageObject('checkWeb_userData');
    if (userData) {
      setUserData(userData);
    }

    if (AllowWeb) {
      setSiteLimitInfo(AllowWeb);
    }
  }, []);

  return (
    <ComparisonDataContext.Provider value={{
      dashComparisonData, setDashComparisonData,
      webStatus, setWebStatus,
      dash_json, setDash_Json,
      userId, setUserId,
      siteName, setSiteName,
      loading, setLoading,
      userSites, setUsersSites,
      siteLimitInfo, setSiteLimitInfo,
      isDownloading, setIsDownloading,
      timerData, setTimerData,
      screenShot, setScreenShot,
      partialData, setPartialData,
      userData, setUserData,
      siteData, setSiteData,

    }}>
      {children}
    </ComparisonDataContext.Provider>
  );
};
